import React from "react"; import PageTitle from "../../Component/Comman/PageTitle";
import TradeSignal from "../../Component/Bot/TradeSignal";


function Bot() {
    return (<>
        <div className="body-header border-bottom d-flex py-3 mb-3">
            <div className="container-xxl">
                <div className="row align-items-center g-2">
                    <PageTitle pagetitle='Trade Signals' sidebutton={true}/>
                </div>
            </div>
        </div>
        <div className="container-xxl">
            <div className="row g-3 mb-3">
                <div className="col-md-12">
                    
                    <TradeSignal />
                    
                </div>
            </div>
        </div>
    </>
    )
}

export default Bot;