import React from 'react'
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

function Chart() {

    return (
        <div className="card">
            <div className="card-body">
                <div className="tradingview-widget-container">
                    <div id='tradingview_e05b7' style={{ height: '700px', margin: '20px' }}>
                        <div id='tradingview_d89db-wrapper' style={{ position: "relative", boxSizing: "content-box", width: "100%", height: "100%", margin: " 0 auto !important", padding: "0 !important" }} >
                            <div style={{ width: "100%", height: "100%", background: "transparent", padding: "0 !important" }}>
                                <AdvancedRealTimeChart style="1" autosize={true} range="12M" interval="D" studies={["BollingerBandsR@tv-basicstudies", "IchimokuCloud@tv-basicstudies"]} details={true} hostlist={true} calendar={true}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chart;