import React, { useState, useEffect, useRef } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import exportingModule from 'highcharts/modules/exporting';
import indicatorsAll from 'highcharts/indicators/indicators-all';
import accessbility from 'highcharts/modules/accessibility';
import dataModule from 'highcharts/modules/data';

// Initialize Highcharts modules
dataModule(Highcharts);
exportingModule(Highcharts);
indicatorsAll(Highcharts);
accessbility(Highcharts);

function getLinearRegression(xData, yData) {
    // Assuming yData is an array of [open, high, low, close]
    var closeData = yData.map(point => point[3]); // Extracting the "Close" value

    var sumX = 0,
        sumY = 0,
        sumXY = 0,
        sumX2 = 0,
        linearData = [],
        linearXData = [],
        linearYData = [],
        n = xData.length,
        alpha, beta, i, x, y;

    // Get sums:
    for (i = 0; i < n; i++) {
        x = xData[i];
        y = closeData[i]; // Using "Close" value for y
        sumX += x;
        sumY += y;
        sumXY += x * y;
        sumX2 += x * x;
    }

    // Get slope and offset:
    alpha = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
    beta = (sumY - alpha * sumX) / n;

    // Calculate linear regression:
    for (i = 0; i < n; i++) {
        x = xData[i];
        y = alpha * x + beta;

        // Prepare arrays required for getValues() method
        linearData[i] = [x, y];
        linearXData[i] = x;
        linearYData[i] = y;
    }

    return {
        xData: linearXData,
        yData: linearYData,
        values: linearData
    };
}
  
Highcharts.seriesType(
    'customlinearregression',
    'sma', {
      name: 'Linear Regression',
      enableMouseTracking: false,
      marker: {
        enabled: false
      },
      params: {} // linear regression doesn’t need params
    }, {
      getValues: function(series) {
        console.log('linear regression', series);
        return this.getLinearRegression(series.xData, series.yData);
      },
      getLinearRegression: getLinearRegression
    }
);
  

const HighChart = () => {
  const [data, setData] = useState({ ohlc: [], volume: [] });
  const [overlays, setOverlays] = useState('pc');
  const [oscillators, setOscillators] = useState('macd');
  const [chart, setChart] = useState(null);
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = chartRef.current.chart;
    setChart(chart);

    const fetchData = async () => {
      console.log('Fetching data..');
      const response = await fetch('https://demo-live-data.highcharts.com/aapl-ohlcv.json');
      const jsonData = await response.json();

      const ohlc = [];
      const volume = [];
      jsonData.forEach(point => {
        ohlc.push([
          point[0], // the date
          point[1], // open
          point[2], // high
          point[3], // low
          point[4] // close
        ]);

        volume.push([
          point[0], // the date
          point[5] // the volume
        ]);
      });

      setData({ ohlc, volume, jsonData });
    };

    fetchData();
  }, []);

  const overlaysOptions = [
    "abands", "bb", "dema", "ema", "ikh", "keltnerchannels", 
    "linearRegression", "pivotpoints", "pc", "priceenvelopes", 
    "psar", "sma", "supertrend", "tema", "vbp", "vwap", "wma", "zigzag", "customlinearregression"
  ];

  const oscillatorsOptions = [
    "apo", "ad", "aroon", "aroonoscillator", "atr", "ao", "cci", 
    "chaikin", "cmf", "disparityindex", "cmo", "dmi", "dpo", 
    "linearRegressionAngle", "linearRegressionIntercept", 
    "linearRegressionSlope", "klinger", "macd", "mfi", "momentum", 
    "natr", "obv", "ppo", "roc", "rsi", "slowstochastic", "stochastic", 
    "trix", "williamsr"
  ];

  const options = {
    chart: {
      height: 800
    },
    title: {
      text: 'AAPL Historical'
    },
    subtitle: {
      text: 'All indicators'
    },
    accessibility: {
        series: {
            descriptionFormat: '{seriesDescription}.'
        },
        description: 'Use the dropdown menus above to display different indicator series on the chart.',
        screenReaderSection: {
            beforeChartFormat: '<{headingTagName}>{chartTitle}</{headingTagName}><div>{typeDescription}</div><div>{chartSubtitle}</div><div>{chartLongdesc}</div>'
        }
    },
    legend: {
        enabled: true
    },
    rangeSelector: {
        selected: 2
    },
    yAxis: [{
        height: '60%'
    }, {
        top: '60%',
        height: '20%'
    }, {
        top: '80%',
        height: '20%'
    }],
    plotOptions: {
        series: {
            showInLegend: true,
            accessibility: {
                exposeAsGroupOnly: true
            }
        }
    },
    series: [{
        type: 'candlestick',
        id: 'aapl',
        name: 'AAPL',
        data: data.jsonData
    }, 
    {
        type: 'column',
        id: 'volume',
        name: 'Volume',
        data: data.volume,
        yAxis: 1
    }, {
        type: 'pc',
        id: 'overlay',
        linkedTo: 'aapl',
        yAxis: 0
    }, {
        type: 'macd',
        id: 'oscillator',
        linkedTo: 'aapl',
        yAxis: 2
    }]
  };

  useEffect(() => {
    if (chart) {
      console.log('Update series..', chart);  
      updateSeries('overlay', overlays);
      updateSeries('oscillator', oscillators);
    }
  }, [overlays, oscillators, chart]);

  const updateSeries = (id, type) => {
    console.log('updateSeries called', id, type);
    const series = chart.get(id);
    if (series) {
      series.remove(false);
      chart.addSeries({
        type: type,
        linkedTo: 'aapl',
        id: id,
        yAxis: id === 'oscillator' ? 2 : 0
      });
    }
  };

  return (
    <div className="card">
        <div className="card-body">
          <div className="main-wrapper">
            <div className="selectors-container">
              <div className="col">
                <label htmlFor="overlays">Overlays:</label>
                <select value={overlays} onChange={e => setOverlays(e.target.value)} id="overlays">
                  {overlaysOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div className="col">
                <label htmlFor="oscillators">Oscillators:</label>
                <select value={oscillators} onChange={e => setOscillators(e.target.value)} id="oscillators">
                  {oscillatorsOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
            </div>
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={options}
              ref={chartRef}
            />
          </div>
        </div>
    </div>
  );
};

export default HighChart;
