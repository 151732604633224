import React from "react"; import PageTitle from "../../Component/Comman/PageTitle";
import Chart from "../../Component/Exchange/Chart";
import { Ticker } from "react-ts-tradingview-widgets";
import GuruFundamentalWidget from "../../Component/Guru/GuruFundamentalWidget";
import HighChart from "../../Component/Exchange/HighChart";


function Exchange() {
    return (<>
        <div className="body-header border-bottom d-flex py-3 mb-3">
            <div className="container-xxl">
                <div className="row align-items-center g-2">
                    <PageTitle pagetitle='Market Analysis' reportbutton='Generate Report' />
                </div>
            </div>
        </div>
        <div className="container-xxl">
            <div className="row g-3 mb-3">
                <div className="col-md-12">
                    
                    <Chart />
                    
                </div>
            </div>
            <div className="row g-3 mb-3">
                 <div className="col-md-12">
                    <HighChart />
                </div>
                {/** 
                <div className="col-xxl-7">
                    <Spot />
                    <SpotTradStatus />
                </div>
                */}
                <div className="col-xxl-7" >
                    <GuruFundamentalWidget
                        height="850px"
                        width="620px"
                        />
                </div>
            </div>
        </div>
    </>
    )
}

export default Exchange;