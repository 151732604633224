import React, { useEffect } from 'react';

const GuruFundamentalWidget = ({ stock = "GOOGL", width = "100%", height = "100%" }) => {
  useEffect(() => {
    const initializeWidget = () => {
      const widgetConfig = {
        "type": "stock",
        "options": {"symbol": stock},
        "dark": false,
        "width": width,
        "height": height,
        "r": "335be3a3162aeeddf5997db5e934cf79"
      };

      const t = document.querySelector(".gurufocus-widget-container");
      if (t) {
        const o = document.createElement("iframe");
        o.style.border = "none";
        o.style.width = "100%";
        o.style.height = "100%";
        const n = {};
        if (widgetConfig.dark) n.dark = widgetConfig.dark;
        if (widgetConfig.options) Object.assign(n, widgetConfig.options);
        t.innerHTML = "";
        t.style.width = widgetConfig.width;
        t.style.height = widgetConfig.height;
        if (widgetConfig.r) n.r = widgetConfig.r;
        n.utm_source = window.location.hostname;
        n.utm_medium = "widget";
        n.utm_campaign = "widget_" + widgetConfig.type;
        const r = Object.keys(n).map(e => e + "=" + encodeURIComponent(n[e])).join("&");
        o.src = "https://www.gurufocus.com/widgets/" + widgetConfig.type + "?" + r;
        t.appendChild(o);
      } else {
        console.error("[gurufocus-widget]Container not found");
      }
    };

    initializeWidget();
  }, [stock, width, height]); // Add these as dependencies for the effect

  return (
    <div className="card">
      
        <div className="gurufocus-widget" style={{ width: '100%', height: '100%', overflow: 'hidden', margin: '20px' }}>
          <div className="gurufocus-widget-container" style={{ width: '100%', height: '100%', overflow: 'hidden' }}></div>
        </div>
    
    </div>
  );
};

export default GuruFundamentalWidget;
