import React, { useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import tradeInstructions from './TradeInstructions';
import { Typography, Box, Link } from '@mui/material';
import ReactMarkdown from 'react-markdown';


const TradeSignal = () => {

    const [inputSymbol, setInputSymbol] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedSymbol, setSelectedSymbol] = useState('');
    const [hoveredSymbol, setHoveredSymbol] = useState('');

    const [strategy, setStrategy] = useState('');
    const [mode, setMode] = useState('');
    const [reportImage, setReportImage] = useState('');
    const [reportData, setReportData] = useState([]);

    const [newsData, setNewsData] = useState([]);
    const [newsAnalysis, setNewsAnalysis] = useState('');
    const [analysisData, setAnalysisData] = useState(null);

    const [selectedRowData, setSelectedRowData] = useState(null);

    const baseUrl = process.env.REACT_APP_AI_URL;

    const handleChangeStrategy = (e) => {
        setStrategy(e.target.value);
    };

    const handleChangeMode = (e) => {
        setMode(e.target.value);
    };

    const handleInputChange = (e) => {
        setInputSymbol(e.target.value);
    };

    const handleSearchClick = async () => {
        try {
            const response = await axios.get(`${baseUrl}/search_symbol/${inputSymbol}`);
            setSuggestions(response.data.symbols);
            setSelectedSymbol(''); // Reset selected symbol on new search
        } catch (error) {
            console.error('Error fetching the symbols:', error);
        }
    };

    const renderSignal = (signal) => {
        const color = signal === 'buy' ? 'green' : (signal === 'sell' ? 'red' : 'black');
        return <span style={{ color }}>{signal.charAt(0).toUpperCase() + signal.slice(1)}</span>;
    };

    const handleGenerateReport = async () => {
        console.log(`url = ${baseUrl}`)
        try {
            const response = await axios.get(`${baseUrl}/get_trade_signals_0/`, {
                params: {
                    symbol: selectedSymbol,
                    symbol_type: selectedRowData.typeDisp,
                    symbol_info: selectedRowData.shortname,
                    exchange: selectedRowData.exchDisp,
                    strategy: strategy,
                    mode: mode,
                    dark_theme: false
                }
            });

            console.log(response.data);
            setReportImage(response.data.image);
            setReportData(response.data.data);

            fetchNewsData();

            fetchAnalysisData();
        } catch (error) {
            console.error('Error generating the report:', error);
        }
    };

    const handleRowClick = (symbol, rowData) => {
        setInputSymbol(symbol);
        setSelectedSymbol(symbol);
        setSelectedRowData(rowData);
    };

    const fetchNewsData = async () => {
        try {
            const response = await axios.get(`${baseUrl}/get_news_0/`, {
                params: {
                    symbol: selectedSymbol,
                    symbol_info: selectedRowData.shortname
                }
            });

            setNewsData(response.data.data);
            setNewsAnalysis(response.data.analysis);
        } catch (error) {
            console.error('Error fetching news data:', error);
        }
    };

    const fetchAnalysisData = async () => {
        try {
            const response = await axios.get(`${baseUrl}/get_insight_analysis_0/`, {
                params: {
                    symbol: selectedSymbol,
                    symbol_info: selectedRowData.shortname
                }
            });

            setAnalysisData(response.data.analysis);
        } catch (error) {
            console.error('Error fetching news data:', error);
        }
    };

    const reversedTradeData = [...reportData].reverse();

    return (
    <div>
        <div className='card'>
            <div className="card-body">
                <div className="row g-3">
                    <div className="col-lg-4">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Enter Symbol" value={inputSymbol} onChange={handleInputChange}/>
                            <button className="btn btn-outline-secondary" type="button" onClick={handleSearchClick}>Search</button>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <select className="form-select" value={strategy} onChange={handleChangeStrategy}>
                            <option value="">Select Strategy</option>
                            <option value="long-term">Long-term Investment</option>
                            <option value="mid-term">Mid-term Trading</option>
                            <option value="short-term">Short-term Trading</option>
                        </select>
                    </div>
                    <div className="col-lg-4">
                        <select className="form-select" value={mode} onChange={handleChangeMode}>
                            <option value="">Trading Mode</option>
                            <option value="relaxed">Relaxed Mode: Increased signals, reduced accuracy</option>
                            <option value="strict">Strict Mode: Fewer signals, higher accuracy</option>
                        </select>
                    </div>
                    
                </div>
    
                <div className="row g-3 m-1">
                    <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Symbol</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Industry</TableCell>
                                        <TableCell>Sector</TableCell>
                                        <TableCell>Exchange</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {suggestions.map((item, index) => (
                                        <TableRow 
                                            key={index} 
                                            onClick={() => handleRowClick(item.symbol, item)}
                                            onMouseEnter={() => setHoveredSymbol(item.symbol)}
                                            onMouseLeave={() => setHoveredSymbol('')}
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: selectedSymbol === item.symbol ? '#FFA533' : (hoveredSymbol === item.symbol ? '#f8f9fa' : ''),
                                                color: selectedSymbol === item.symbol ? 'white' : 'inherit',
                                            }}>
                                            <TableCell>{item.symbol}</TableCell>
                                            <TableCell>{item.shortname}</TableCell>
                                            <TableCell>{item.typeDisp}</TableCell>
                                            <TableCell>{item.industry}</TableCell>
                                            <TableCell>{item.sector}</TableCell>
                                            <TableCell>{item.exchDisp}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div className="row g-3 mt-1">
                        <div className="col-lg-3">
                            <button type="button" className="btn btn-primary" onClick={handleGenerateReport}>
                                <i className="fa fa-refresh px-1" aria-hidden="true"></i>Generate Report
                            </button>
                        </div>
                    </div>

            </div>

        </div>

        <div className='card mt-2'>
            <div className="card-body">
                <Box style={{ padding: '20px', borderRadius: '8px', margin: '20px' }}>
                    <Typography variant="h4" component="h3" style={{marginBottom: '5px'}}>
                        Understanding Our Signal Chart
                    </Typography>
                    <ReactMarkdown>
                        {tradeInstructions.understandingCharts}
                    </ReactMarkdown>

                    <Typography variant="h5" component="h3" style={{marginBottom: '5px'}}>
                        Strategy Insights
                    </Typography>
                    <ReactMarkdown variant="body1">
                        {tradeInstructions.strategyInsights}
                    </ReactMarkdown>

                    <div style={{ backgroundColor: '#eee', padding: '10px', borderRadius: '5px' }}>
                        <Typography variant="h5" component="h3" style={{marginBottom: '5px'}}>
                            Disclaimer
                        </Typography>
                        <ReactMarkdown variant="body1">
                            {tradeInstructions.disclaimer}
                        </ReactMarkdown>
                    </div>
                </Box>

                {reportImage && (
                    <div className="row g-3 mt-1">
                        <img src={`data:image/png;base64,${reportImage}`} alt="Trade Signal Report" style={{ maxWidth: '100%' }} />
                    </div>
                )}

                {reportData && reportData.length > 0 && (
                    <div className="row g-3 m-1">
                        <div className="col">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Price</th>
                                        <th>Signal</th>
                                        <th>Confidence</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reversedTradeData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.date}</td>
                                            <td>{item.price}</td>
                                            <td>{renderSignal(item.signal)}</td>
                                            <td>{Math.round(item.confidence)}%</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

            </div>
        </div>

        {newsData && newsData.length > 0 && (
            <div className='card mt-2'>

                <div className="card-body">
                        <Box style={{ padding: '20px', borderRadius: '8px', margin: '20px' }}>
                            
                            <ReactMarkdown>
                                {newsAnalysis}
                            </ReactMarkdown>
                        </Box>
                        <div className="row g-3 m-1">
                            <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>Date Published</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>News Headlines</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }}>Sentiment</TableCell>  {/* New Column for Sentiment */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {newsData.map((newsItem, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{new Date(newsItem.pubDate).toLocaleDateString()}</TableCell>
                                            <TableCell>
                                                <Link href={newsItem.link} target="_blank" rel="noopener noreferrer">
                                                    {newsItem.title}
                                                </Link>
                                            </TableCell>
                                            <TableCell style={{ color: newsItem.sentiment === 'positive' ? 'green' : 'red' }}>
                                                {newsItem.sentiment.charAt(0).toUpperCase() + newsItem.sentiment.slice(1)} {/* Capitalize the first letter */}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </div>
                </div>
            </div>
        )}

        {analysisData && (
            <div className='card mt-2'>
                <div className="card-body">
                    <Box style={{ padding: '20px', borderRadius: '8px', margin: '20px' }}>
                        <ReactMarkdown>
                            {analysisData}
                        </ReactMarkdown>
                    </Box>
                </div>
            </div>
        )}

    </div>
    );
};

export default TradeSignal;
